<template>
  <van-popup
    class="popup-indicator-echarts"
    :style="{ height: '65vw', width: '95vw' }"
    v-bind="$attrs"
    @opened="popupOnOpened"
    @click-overlay="onClose"
  >
    <span
      class="closeecharts iconfont iconfont-danchuangguanbi"
      @click="onClose"
    ></span>
    <div class="popup-indicator-title">
      <span class="popup-indicator-title-name">{{ model.name }}</span>
      <span class="popup-indicator-title-poi"
        ><i class="iconfont iconfont-quyu1"></i> {{ model.poiName }}</span
      >
    </div>
    <div class="popup-indicator-warning">
      <span
        >低低报:
        {{
          model.lowLow !== defaultValue
            ? model.lowLow + model.indicatorUnitName
            : defaultValue
        }}</span
      >
      <span
        >低报:
        {{
          model.low !== defaultValue
            ? model.low + model.indicatorUnitName
            : defaultValue
        }}</span
      >
      <span
        >高报:
        {{
          model.high !== defaultValue
            ? model.high + model.indicatorUnitName
            : defaultValue
        }}</span
      >
      <span
        >高高报:
        {{
          model.highHigh !== defaultValue
            ? model.highHigh + model.indicatorUnitName
            : defaultValue
        }}</span
      >
    </div>
    <div
      id="popup-indicator-id"
      class="popup-indicator-echarts-box"
      :class="{ nodata: echartsNoData }"
    ></div>
  </van-popup>
</template>

<script>
import { getIndicatorEcharts } from "@/api/base";
import { getEchartOption } from "../indicatorEchartsOptions";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/markLine";

export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      defaultValue: "--",
      echartsNoData: false
    };
  },
  methods: {
    popupOnOpened() {
      // 获取图表数据
      let { id } = this.$route.params;
      if (!id) {
        id = this.model.id;
      }
      getIndicatorEcharts({
        indicatorIds: id
      }).then(res => {
        this.echartsNoData = res.length === 0;
        this.initEcharts(res);
      });
    },
    initEcharts(res) {
      if (!this.echarts) {
        this.echarts = echarts.init(
          document.getElementById("popup-indicator-id")
        );
      }
      this.echarts.clear();
      const options = getEchartOption();
      let d = {};
      if (Array.isArray(res)) {
        d = res[0] || {};
      } else {
        d = res;
      }
      const xAxisData = d?.indicatorChartData?.xaxis || [];
      const ydata = (d?.indicatorChartData?.yaxis || []).map(a => {
        return a || 0;
      });
      let high = this.model.high;
      let low = this.model.low;
      options.xAxis.data = xAxisData;
      options.series = [
        {
          data: ydata,

          type: "line",
          symbol: "none",
          lineStyle: {
            width: 1
          },
          markLine: {
            symbol: ["circle", "arrow"],
            silent: true,
            data: []
          }
        }
      ];
      if (high != "--") {
        options.series[0].markLine.data.push({
          yAxis: high,
          lineStyle: {
            color: "#F55F5F",
            width: 1
          },
          label: {
            normal: {
              show: true,
              position: "right",
              formatter: "高报",
              textStyle: {
                color: "#F55F5F", //标注线终点文字颜色
                fontSize: 12,
                padding: [0, 2, 0, 0] //文字间距
              }
            }
          }
        });
      }
      if (low != "--") {
        options.series[0].markLine.data.push({
          yAxis: low,
          lineStyle: {
            color: "#F55F5F",
            width: 1
          },
          label: {
            normal: {
              show: true,
              position: "right",
              formatter: "低报",
              textStyle: {
                color: "#F55F5F", //标注线终点文字颜色
                fontSize: 12,
                padding: [0, 2, 0, 0] //文字间距
              }
            }
          }
        });
      }
      this.echarts.setOption(options);
      return this.echarts;
    },
    onClose() {
      this.showEcharts(false);
      this.echarts && this.echarts.clear();
    },
    showEcharts(v) {
      this.$emit("change", v);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-indicator-echarts {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding-top: 18px;
  .closeecharts {
    position: absolute;
    font-size: 18px;
    top: 8px;
    right: 8px;
    color: #9496a3;
  }
  .popup-indicator-title {
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    padding-right: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #3b4664;
    align-items: center;
    &-name {
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 70%;
    }
    &-poi {
      white-space: nowrap;
      color: #aeb3c0;
      font-size: 12px;
      vertical-align: top;
    }
  }
  .popup-indicator-warning {
    padding: 9px 16px;
    font-size: 12px;
    color: #3b4664;
    span {
      &:not(:last-child) {
        margin-right: 14px;
        position: relative;
        &::after {
          position: absolute;
          content: "/";
          right: -8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .popup-indicator-echarts-box {
    flex: 1;
    position: relative;
    &.nodata {
      &::after {
        position: absolute;
        content: "暂无数据";
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
