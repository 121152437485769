<template>
  <div class="indicator">
    <div class="indicator-top">
      <van-nav-bar
        title="实时监测"
        left-arrow
        :border="false"
        @click-left="onClickLeft"
      >
      </van-nav-bar>

      <van-search
        v-model="searchName"
        placeholder="请输入点位名称或工艺位号关键字"
        class="search-bar"
        @search="onSearch"
        @clear="onCancel"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <div class="department-box">
        <div ref="departmentList" class="department-list">
          <span
            v-for="(item, index) in departmentList"
            ref="dept"
            :key="item.department"
            class="department-item"
            :class="{ active: form.selectDeptIds.includes(item.department) }"
            @click="selectDept(item, index)"
            >{{ item.departmentName }}</span
          >
        </div>
        <div class="department-filter">
          <i class="iconfont iconfont-peizhi" @click="selectCon(3)"></i>
        </div>
      </div>
      <div ref="filterBox" class="filter-box">
        <span
          v-for="(item, index) in filterList"
          ref="filterItems"
          :key="index"
          class="filter-item"
          :class="[item.type, { active: item.isActive || item.text }]"
          @click="selectCon(item.index, item, index)"
          >{{ item.text || item.name }}</span
        >
      </div>
      <div class="indicator-total">共{{ total }}个监测点位</div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="refreshing"
      class="indicator-list"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText || '没有更多了'"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <!--        @load="onLoad"-->
        <div
          v-for="item in indicatorList"
          :key="item.id"
          class="indicator-item"
          @click="gotoIndicatorDetail(item.id)"
        >
          <div class="title-contariner">
            <div class="indicator-name">
              {{ item.name }}{{ item.num ? `(${item.num})` : "" }}
            </div>
            <div
              v-if="item.value || item.value === 0"
              class="indicator-value"
              @click.stop="showEcharts(true, item)"
            >
              {{
                item.value || item.value === 0
                  ? item.value + item.indicatorUnitName
                  : ""
              }}<i
                :class="{ active: item.handleStatus === 0 }"
                class="iconfont iconfont-lansezhexian"
              ></i>
            </div>
          </div>
          <div class="indicator-other-item">
            <span class="indicator-part"
              ><i class="iconfont iconfont-bumen"></i>
              {{ item.departmentName || "--" }}</span
            >
            <span class="device-info"
              ><i class="iconfont iconfont-quyu1"></i
              >{{ item.poiName || "--" }}</span
            >
            <span v-if="item.alarmGroupName" class="level-tag">{{
              item.alarmGroupName
            }}</span>
            <span v-if="item.envGroupName" class="dispose">{{
              item.envGroupName
            }}</span>
            <span v-if="item.major === 1" class="indicators">重点指标</span>
            <span v-if="['1', '2'].includes(item.sensorType)" class="dispose"
              >气体</span
            >
            <span
              v-if="item.levelCode"
              class="danger-source"
              :class="dangerLevelObj[item.levelCode]"
              >危险源</span
            >
          </div>
          <div class="indicator-other-item">
            <i class="iconfont iconfont-baojingyuzhi"></i>
            <span class="indicator-noun"
              >低低
              {{
                item.lowLow ? item.lowLow + item.indicatorUnitName : "-"
              }}</span
            >
            <span class="indicator-noun"
              >低 {{ item.low ? item.low + item.indicatorUnitName : "-" }}</span
            >
            <span class="indicator-noun"
              >高
              {{ item.high ? item.high + item.indicatorUnitName : "-" }}</span
            >
            <span class="indicator-noun"
              >高高
              {{
                item.highHigh ? item.highHigh + item.indicatorUnitName : "-"
              }}</span
            >
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <SelectPopupGrade
      ref="selectPopupGrade1"
      v-model="popupVisibleObj[1].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visible"
      @change="v => popupGradeChange(v, 1)"
      @close="popupVisibleObj[1].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade2"
      :immediately="false"
      :title="popupVisibleObj[2].title"
      :list="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visible"
      @close="popupVisibleObj[2].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade5"
      v-model="popupVisibleObj[5].value"
      :multi="true"
      :immediately="false"
      :title="popupVisibleObj[5].title"
      :list="popupVisibleObj[5].list"
      :visible="popupVisibleObj[5].visible"
      @change="v => popupGradeChange(v, 5)"
      @close="popupVisibleObj[5].visible = false"
    />
    <SelectPopupGrade
      ref="selectPopupGrade6"
      v-model="popupVisibleObj[6].value"
      :multi="true"
      :immediately="false"
      :title="popupVisibleObj[6].title"
      :list="popupVisibleObj[6].list"
      :visible="popupVisibleObj[6].visible"
      @change="v => popupGradeChange(v, 6)"
      @close="popupVisibleObj[6].visible = false"
    />
    <add-facility
      :visible="popupVisibleObj[2].visible"
      :title="popupVisibleObj[2].title"
      @close="popupVisibleObj[2].visible = false"
      @confirm="v => popupGradeChange(v, 2)"
    />
    <SelectPopupGrade
      ref="selectPopupGrade3"
      v-model="departmentIds"
      popup-height="75%"
      :is-show-active-bgc="false"
      :immediately="false"
      :multi="true"
      :is-show-footer="true"
      :is-show-confrim-icon="false"
      list-key="value"
      filter-key="tagName"
      :title="popupVisibleObj[3].title"
      :list="popupVisibleObj[3].list"
      :visible.sync="popupVisibleObj[3].visible"
      placeholder="请输入机构标签"
      @change="v => popupGradeChange(v, 3)"
      @reset="$refs.selectPopupGrade3.selectAll(true)"
      @sure="updateAttentionDept"
    >
      <div
        slot="content"
        slot-scope="scope"
        :class="{ active: scope.row.isActive }"
        class="dept-item"
      >
        <div class="dept-item__left">
          <span class="dept-name text-overflow">{{ scope.row.label }}</span>
          <span class="dept-tag">{{ scope.row.tagName }}</span>
        </div>
        <span class="dept-status">
          <i
            v-if="scope.row.isActive"
            class="iconfont iconfont-danxuan-yixuan"
          ></i>
          <i v-else class="iconfont iconfont-danxuan-weixuan"></i>
        </span>
      </div>
    </SelectPopupGrade>
    <SelectPopupGrade
      ref="selectPopupGrade4"
      v-model="popupVisibleObj[4].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[4].title"
      :list="popupVisibleObj[4].list"
      :visible="popupVisibleObj[4].visible"
      @change="v => popupGradeChange(v, 4)"
      @close="popupVisibleObj[4].visible = false"
    />
    <IndicatorEchartsPopup v-model="echartsVisible" :model="model" />

    <SelectPopupGrade
      ref="selectPopupGrade4"
      v-model="popupVisibleObj[7].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[7].title"
      :list="popupVisibleObj[7].list"
      :visible="popupVisibleObj[7].visible"
      @change="v => popupGradeChange(v, 7)"
      @close="popupVisibleObj[7].visible = false"
    />
  </div>
</template>

<script>
import SelectPopupGrade from "@/components/SelectPopupGrade";
import {
  getDangerSelect,
  getIndicatorDic,
  getIndicatorList,
  getAttentionDeptList,
  updateAttentionDeptList
} from "@/api/base";
import {
  getIndicatorIdsSelect,
  getEnvGroupIndicatorIdsSelect
} from "@/api/warning";
import IndicatorEchartsPopup from "./components/indicatorEchartsPopup";
import { mapState } from "vuex";
import { BUSINESSTYPE } from "../workticket/utils/constant";
import AddFacility from "@/components/AddFacility";
export default {
  name: "IndicatorList",
  components: {
    AddFacility,
    SelectPopupGrade,
    IndicatorEchartsPopup
  },
  data() {
    return {
      departmentIds: [],
      departmentList: [],
      filterList: [
        {
          name: "工艺报警指标",
          type: "select",
          text: "",
          index: 5,
          isActive: false
        },
        {
          name: "环保指标",
          type: "select",
          index: 6,
          text: "",
          isActive: false
        },
        {
          name: "气体",
          index: "gas",
          type: "radio",
          isActive: false
        },
        {
          name: "重点指标",
          type: "radio",
          index: "major",
          isActive: false
        },
        {
          name: "重大危险源",
          type: "select",
          index: 1,
          text: "",
          isActive: false
        },
        {
          name: "监测项",
          type: "select",
          index: 4,
          text: "",
          isActive: false
        },
        {
          name: "监测设备设施",
          type: "device",
          index: 2,
          text: "",
          isActive: false
        },
        {
          name: "网络连接状态",
          type: "select",
          index: 7,
          text: "",
          isActive: false
        }
      ],
      finishedText: "",
      echartsVisible: false,
      dangerLevelObj: {
        100311: "level1",
        100312: "level2",
        100313: "level3",
        100314: "level4"
      },
      total: 0,
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      searchName: "",
      visible: false,
      popupFilterVisible: false,
      indicatorList: [],
      query: {
        page: 1,
        size: 10,
        envType: 0, // 是否是重点污染排放
        dangerIdList: "", // 危险源id
        poiIdList: "", // 设施id
        department: "", // 部门id
        subDepartment: true,
        indicatorTypeList: "", // 传感器类型(检测项)
        fuzzyName: "",
        gasType: null, // 是否气体检测0:否,1:是
        craft: [], // 工艺报警指标
        envGroupIndicatorIdList: [], // 环保指标,
        runningStatus: "" // 网络连接状态
      },
      popupStyle: {
        height: "100%"
      },
      popupVisibleObj: {
        1: {
          visible: false,
          title: "重大危险源",
          value: "",
          list: []
        },
        2: {
          visible: false,
          title: "监测设备设施"
        },
        3: {
          visible: false,
          title: "关注部门",
          list: []
        },
        4: {
          visible: false,
          title: "监测项",
          value: "",
          list: []
        },
        5: {
          visible: false,
          title: "工艺报警指标",
          value: "",
          list: []
        },
        6: {
          visible: false,
          title: "环保指标",
          value: "",
          list: []
        },
        7: {
          visible: false,
          title: "网络连接状态",
          value: "",
          list: [
            {
              label: "在线",
              value: "1"
            },
            {
              label: "离线",
              value: "0"
            }
          ]
        }
      },
      form: {
        checked: false,
        major: null,
        dangerName: "",
        poiName: "",
        departmentName: "",
        departmentId: "",
        indicatorType: "",
        selectDeptIds: [],
        runningStatusName: ""
      },
      model: {
        id: "",
        name: "",
        num: "",
        value: "",
        collectTime: "",
        indicatorTypeName: "",
        threshold: "",
        alarmName: "",
        alarmTime: "",
        departmentName: "",
        poiName: "",
        dangerName: "",
        dutyMobile: "",
        hseHeader: "",
        hsdMobile: "",
        low: "",
        lowLow: "",
        high: "",
        highHigh: "",
        indicatorUnitName: ""
      }
    };
  },
  created() {
    this.defaultValue = "--";
    this.getSelect();
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  async activated() {
    if (this.needRefreshList) {
      await this.onSearch();
      this._setNeedRefreshList(false);
    }
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
  },
  methods: {
    // 选中部门
    selectDept(item, index) {
      const i = this.form.selectDeptIds.findIndex(a => a === item.department);
      if (i === -1) {
        this.form.selectDeptIds.push(item.department);
      } else {
        this.form.selectDeptIds.splice(i, 1);
      }
      this.setDepartmentParams(this.form.selectDeptIds);
      this.onSearch(this.searchName);
    },
    updateAttentionDept(r) {
      updateAttentionDeptList({
        orgCode: this.userInfo.orgCode,
        cuserId: this.userInfo.id,
        businessType: BUSINESSTYPE.indicator,
        departmentList: r.map(a => a.value)
      }).then(res => {
        // 更新成功关注部门之后需要重新获取列表获取最新状态
        this.getAttentionDeptList();
      });
    },
    showEcharts(v, row) {
      for (let key in this.model) {
        this.model[key] = row[key] || this.defaultValue;
      }
      this.echartsVisible = v;
    },
    // 设置部门入参
    setDepartmentParams(arr) {
      this.form.selectDeptIds = arr;
      this.query.department = arr.join(",");
    },
    getAttentionDeptList() {
      return getAttentionDeptList({
        orgCode: this.userInfo.orgCode,
        cuserId: this.userInfo.id,
        businessType: BUSINESSTYPE.indicator
      }).then(res => {
        const l = res.filter(a => a.attentionStatus === 1);
        // l 是最新的 this.departmentList: 是旧的
        this.departmentList = l;
        const arr = [];
        this.form.selectDeptIds.forEach(item => {
          if (this.departmentList.find(a => a.department === item)) {
            arr.push(item);
          }
        });
        if (arr.length !== this.form.selectDeptIds.length) {
          this.setDepartmentParams(arr);
          this.onSearch(this.searchName);
        }
        this.departmentIds = this.departmentList.map(a => a.department);
        this.popupVisibleObj[3].list = res.map(a => {
          return {
            ...a,
            label: a.departmentName,
            value: a.department
          };
        });
      });
    },
    getSelect() {
      getDangerSelect().then(res => {
        this.popupVisibleObj[1].list = [
          { label: "全部危险源", value: -1 },
          ...res
        ];
      });
      getIndicatorDic().then(res => {
        this.popupVisibleObj[4].list = res;
      });
      this.getAttentionDeptList();
      getIndicatorIdsSelect({
        orgCode: this.userInfo.orgCode
      }).then(res => {
        const list = res.map(a => {
          return {
            ...a,
            label: a.groupName,
            value: a.groupId,
            id: a.groupId
          };
        });
        this.popupVisibleObj[5].list = list;
      });
      getEnvGroupIndicatorIdsSelect({
        orgCode: this.userInfo.orgCode
      }).then(res => {
        const list = res.map(a => {
          return {
            ...a,
            label: a.groupName,
            value: a.groupId,
            id: a.groupId
          };
        });
        this.popupVisibleObj[6].list = list;
      });
    },
    popupGradeChange(v, type, label) {
      const vObj = v[0];
      if (type === 1) {
        // 危险源
        // 如果是全部危险源
        if (vObj && vObj.value === -1) {
          this.query.dangerIdList = this.popupVisibleObj[type].list
            .filter(a => a.value !== -1)
            .map(a => a.value)
            .join(",");
        } else {
          this.query.dangerIdList = v.map(a => a.value).join(",");
        }
        this.popupVisibleObj[1].value = vObj?.value || "";
        this.form.dangerName = vObj?.label || "";
        this.filterList[4].text = this.form.dangerName;
      } else if (type === 4) {
        // 检测项
        this.query.indicatorTypeList = v.map(a => a.value).join(",");
        this.popupVisibleObj[4].value = this.query.indicatorTypeList;
        this.form.indicatorType = vObj ? vObj.label : "";
        this.filterList[5].text = this.form.indicatorType;
      } else if (type === 2) {
        // 设备设施
        this.query.poiIdList = v.value;
        this.form.poiName = v.label;
        this.filterList[6].text = v.label;
      } else if (type === 5 || type === 6) {
        // 工艺报警指标
        let r = [];
        if (v.length === 1) {
          r = v[0].indicatorIdList;
        } else if (v.length > 1) {
          r = v.reduce((a, b) => {
            return {
              indicatorIdList: a.indicatorIdList.concat(b.indicatorIdList)
            };
          }).indicatorIdList;
        } else {
          r = [];
        }
        if (type === 5) {
          this.query.craft = r.join(",");
          this.popupVisibleObj[5].value = v.map(a => a.id);
          this.filterList[0].text = v.map(a => a.groupName).join(",");
        } else if (type === 6) {
          this.popupVisibleObj[6].value = v.map(a => a.id);
          this.query.envGroupIndicatorIdList = r.join(",");
          this.filterList[1].text = v.map(a => a.groupName).join(",");
        }
      } else if (type === 7) {
        // 网络连接状态
        this.query.runningStatus = v.map(a => a.value).join(",");
        this.popupVisibleObj[7].value = this.query.runningStatus;
        this.form.runningStatusName = vObj ? vObj.label : "";
        this.filterList[7].text = this.form.runningStatusName;
      }
      this.onSearch(this.searchName);
    },
    switchMajor(v) {
      this.query.major = v ? 1 : null;
    },
    switchEnvType(v) {
      this.query.envType = v ? 1 : 0;
    },
    selectCon(index, item, i) {
      // const filterBox = this.$refs.filterBox;
      // const itemBox = this.$refs.filterItems[i];
      // console.log("父宽度", filterBox.offsetWidth, "子宽度", itemBox.offsetWidth);
      // console.log(itemBox.offsetLeft > filterBox.offsetWidth - itemBox.offsetWidth ? "需要移动" : "不需要");
      // if (filterBox.offsetWidth - itemBox.offsetLeft) {

      // }
      // return;
      // 如果是单选
      if (item && item.type === "radio") {
        item.isActive = !item.isActive;
        if (item.index === "gas") {
          this.query.gasType = item.isActive ? 1 : null;
          // 去查询列表
          this.onSearch(this.searchName);
        } else if (item.index === "major") {
          // 重点指标
          this.query.major = item.isActive ? 1 : null;
          // 去查询列表
          this.onSearch(this.searchName);
        }
        return;
      }
      this.popupVisibleObj[index].visible = true;
    },
    gotoIndicatorDetail(id) {
      this.$router.push({ name: "IndicatorDetail", params: { id } });
    },
    async getList(initPage) {
      this.finished = false;
      if (initPage) {
        this.query.page = 1;
      }
      const query = {
        ...this.query,
        fuzzyName: this.searchName
      };
      if (globalConfig.VUE_APP_SENSOR_ONLY_SHOW_LIBRARY === "true") {
        query.quoted = 1;
      }
      try {
        const res = await getIndicatorList(query);
        this.loading = false;
        if (res.list && res.list.length) {
          this.total = res.total;
          res.list.forEach(item => {
            item.value = !isNaN(item.value)
              ? parseFloat(item.value ? item.value.toFixed(2) : 0)
              : "";
          });
          this.indicatorList = this.indicatorList.concat(res.list);
          if (res.list.length < this.query.size) {
            this.finishedText = "没有更多了";
            this.finished = true;
          } else {
            this.query.page++;
          }
        } else {
          if (!this.indicatorList.length) {
            this.finishedText = "没有更多了";
          } else {
            this.finishedText = "";
          }
          this.finished = true;
        }
      } catch (e) {
        console.log(e);
        this.finished = true;
        this.loading = false;
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.indicatorList = [];
        this.refreshing = false;
      }
      this.getList();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      // 将 page 重新设置为 1
      this.query.page = 1;
      this.onLoad();
    },
    async onSearch(val) {
      this.finished = false;
      // this.handleCondition();
      this.loading = true;
      this.query.page = 1;
      this.indicatorList = [];
      this.total = 0;
      await this.getList();
    },
    onCancel() {
      this.finished = false;
      // this.handleCondition();
    },
    onClickLeft() {
      history.go(-1);
    },
    onReset() {
      this.query.envType = 0; // 是否是重点污染排放
      this.query.dangerIdList = ""; // 危险源id
      this.query.poiIdList = ""; // 设施id
      this.query.department = ""; // 部门id
      this.query.indicatorTypeList = ""; // 传感器类型(检测项)
      this.form.checked = false;
      this.form.dangerName = "";
      this.form.poiName = "";
      this.form.departmentName = "";
      this.form.indicatorType = "";
      this.$refs.selectPopupGrade1.setActive("");
      this.$refs.selectPopupGrade2.setActive("");
      this.$refs.selectPopupGrade4.setActive("");
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    next();
  }
};
</script>

<style lang="scss" scoped>
.indicator {
  text-align: left;
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .dept-item {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 14px;
    &.active {
      .dept-name {
        color: $--color-primary;
      }
    }
    &__left {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }
    .dept-name {
      display: inline-block;
      color: #2e2e4d;
      max-width: 100px;
    }
    .iconfont-danxuan-weixuan {
      color: #d4dbe3;
    }
  }
  .indicator-top {
    .search-bar {
      padding-bottom: 0;
    }
    .department-box {
      padding: 0 0 0 16px;
      display: flex;
      flex-direction: row;
      height: 32px;
      .department-list {
        height: 32px;
        font-size: 12px;
        color: #3b4664;
        flex: 1;
        overflow-x: scroll;
        white-space: nowrap;
        display: flex;
        align-items: center;
        &::-webkit-scrollbar {
          display: none;
        }
        .department-item {
          display: inline-block;
          padding: 10px 12px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          &.active {
            color: $--color-primary;
          }
        }
      }
      .department-filter {
        position: relative;
        width: 56px;
        min-width: 56px;
        font-size: 16px;
        color: $--color-primary;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont {
          margin-left: 12px;
        }
        &::before {
          position: absolute;
          content: "";
          left: 12px;
          top: 50%;
          width: 1px;
          height: 16px;
          background-color: #c7c9d0;
          transform: translate3d(0, -50%, 0);
        }
      }
    }
    .filter-box {
      padding: 5px 0;
      font-size: 12px;
      white-space: nowrap;
      overflow-x: scroll;
      border-bottom: 1px solid #c7c9d0;
      &::-webkit-scrollbar {
        display: none;
      }
      .filter-item {
        display: inline-block;
        border-radius: 10px;
        background-color: #eaeff5;
        padding: 4px 6px;
        box-sizing: border-box;
        margin: 0 4px;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #3b4664;
        &.select {
          position: relative;
          padding-right: 18px;
          &::after {
            position: absolute;
            content: "";
            right: 6px;
            top: 8px;
            border: 4px solid transparent;
            border-top-color: #aeb3c0;
          }
          &.active {
            &::after {
              border-top-color: $--color-primary;
            }
          }
        }
        &.active {
          background-color: #dcecff;
          color: $--color-primary;
        }
        &:first-child {
          margin-left: 16px;
        }
        &:last-child {
          margin-right: 16px;
        }
      }
    }
    .indicator-total {
      text-align: center;
      font-size: 12px;
      color: #8c8f97;
      font-weight: 400;
      padding: 12px 0 2px 0;
    }
    .filtericon {
      font-size: 24px;
    }
  }
  .indicator-list {
    flex: 1;
    overflow-y: auto;
    .indicator-item {
      position: relative;
      padding: 10px 16px 13px 16px;
      border-bottom: 8px solid #eceef2;
      .title-contariner {
        display: flex;
        margin-bottom: 10px;
        .indicator-name {
          flex: 1;
          font-size: 14px;
          color: #3b4664;
          font-weight: 500;
          line-height: 16px;
          word-break: break-all;
          padding: 0 12px 0 0;
        }
        .indicator-value {
          font-size: 14px;
          color: #3b4664;
          font-weight: 500;
          .iconfont-lansezhexian {
            width: 12px;
            height: 12px;
            color: #1676ff;
            border-radius: 50%;
            font-size: 12px;
            margin-left: 2px;
            &.active {
              color: #f13030;
            }
          }
        }
      }
      // .indicator-value {
      //   position: absolute;
      //   top: 10px;
      //   right: 16px;
      //   font-size: 14px;
      //   color: #3b4664;
      //   font-weight: 500;
      //   .iconfont-lansezhexian {
      //     width: 12px;
      //     height: 12px;
      //     color: #1676ff;
      //     border-radius: 50%;
      //     font-size: 12px;
      //     margin-left: 2px;
      //     &.active {
      //       color: #f13030;
      //     }
      //   }
      // }
      // .indicator-name {
      //   margin-bottom: 10px;
      //   font-size: 14px;
      //   color: #3b4664;
      //   font-weight: 500;
      //   padding-right: 80px;
      //   line-height: 16px;
      //   word-break: break-all;
      // }
      .indicator-other-item {
        font-size: 12px;
        color: #3b4664;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .iconfont {
          color: #aeb3c0;
          margin-right: 4px;
        }
        .indicator-part {
          margin-right: 20px;
          vertical-align: top;
        }
        .indicator-noun {
          padding: 0 2px;
          margin-right: 4px;
          &:not(:last-child) {
            position: relative;
            &::after {
              position: absolute;
              content: "";
              right: -2px;
              top: 50%;
              transform: translateY(-50%);
              width: 1px;
              height: 8px;
              background-color: #3b4664;
            }
          }
        }
        .device-info {
          display: inline-block;
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: top;
        }
        .danger-source {
          position: relative;
          display: inline-block;
          transform: scale(0.9);
          padding: 2px 4px;
          font-size: 12px;
          margin-left: 8px;
          vertical-align: top;
          top: 1px;
          border-radius: 2px;
          // &::after {
          //   position: absolute;
          //   content: "";
          //   top: 0;
          //   left: 0;
          //   width: 200%;
          //   height: 200%;
          //   border-radius: 2px;
          //   transform: scale(0.5);
          //   transform-origin: 0 0;
          // }
          &.level1 {
            border: 1px solid #f13030;
            color: #f13030;
            background-color: #ffdddd;
          }
          &.level2 {
            border: 1px solid #ff6d1e;
            color: #ff6d1e;
            background-color: #ffe7db;
          }
          &.level3 {
            border: 1px solid #edc400;
            color: #edc400;
            background-color: #fff8d7;
          }
          &.level4 {
            border: 1px solid #1580ff;
            color: #1580ff;
            background-color: #dcecff;
          }
        }
        .level-tag,
        .dispose,
        .indicators {
          position: relative;
          display: inline-block;
          border-radius: 2px;
          background-color: #e4fcd9;
          color: #3f9f11;
          margin-left: 4px;
          transform: scale(0.9);
          padding: 2px 4px;
          font-size: 12px;
          margin-left: 5px;
          top: 1px;
          border: 1px solid #3f9f11;
          // &::after {
          //   position: absolute;
          //   content: "";
          //   top: 0;
          //   left: 0;
          //   width: 200%;
          //   height: 200%;
          //   border-radius: 2px;
          //   transform: scale(0.5);
          //   transform-origin: 0 0;
          //   border: 1px solid #3f9f11;
          // }
        }
        .level-tag {
          background-color: #e1cfff;
          color: #7839e0;
          border-color: #7839e0;
        }
        .indicators {
          color: #d73bbe;
          background: #ffe0ff;
          border: 1px solid #d73bbe;
        }
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .filter-popup {
    .select-popup-container {
      .header {
        position: relative;
        height: 44px;
        line-height: 44px;
        text-align: center;
        .closeicon {
          position: absolute;
          left: 0;
          top: 0;
          width: 44px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #9496a3;
          font-size: 15px;
        }
        .popup-title {
          color: #2e2e4d;
          font-size: 18px;
          margin: 0 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .content {
        padding: 10px 0;
        .van-cell__title {
          flex: none;
          width: 100px;
        }
      }
      .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        .btn-group {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          button:first-of-type {
            margin-right: 13px;
          }
        }
      }
    }
  }
}
</style>
