import dayjs from "dayjs";
export function getEchartOption() {
  return {
    color: ["#1e6a7c"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985"
        }
      }
      // padding: 0,
      // formatter: params => {
      //   const obj = params[0];
      //   console.log("obj", obj);
      //   return `<div class="env-indicator-tooltip">
      //     <div class="tooltip-title">${obj.axisValue}</div>
      //     <div class="tooltip-info">
      //       <span class="tooltip-color" style="background-color: ${obj.color}"></span>
      //       <span>${obj.data}</span>
      //     </div>
      //   </div>`;
      // }
    },
    grid: {
      top: 10,
      left: "3%",
      right: "10%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: "category",
      data: [],
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        formatter: function(value, index) {
          return dayjs(value).format("HH:mm");
        }
      }
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: ["#ddd"],
          type: "dashed"
        }
      }
    },
    series: [
      {
        data: [],
        type: "line"
      }
    ]
  };
}
